/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: 0px;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;

  display: flex;
  align-items: center;

  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  width: 10px;
  height: 10px;
  padding: 0px;
  cursor: pointer;
  border: 0;
  outline: none;
  border-radius: 100%;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;

  width: 10px;
  height: 10px;
  text-align: center;

  opacity: 0.1;
  background-color: black;
  border-radius: 100%;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  color: black;
}

/* ------------------- Slider -------------------- */

.disabled > .slick-list > .slick-track {
  /* width: 100% !important; */
}

.slick-slider {
  position: relative;
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  height: 100%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

.slick-slide > div {
  height: 100%;
}

/* ------------------- Magic Dots -------------------- */

.magic-dots.slick-dots ul {
  padding: 0;
  display: flex;
  transition: all 0.2s;
  position: relative;
  margin: 0;
}
.magic-dots.slick-dots li.slick-active button:before {
  color: #000000;
}
.magic-dots.slick-dots li button:before {
  font-size: 0;
  content: '\2022';
}
.magic-dots.slick-dots li.small button {
  width: 4px;
  height: 4px;
}

.magic-dots.slick-dots li.small button:before {
  width: 4px;
  height: 4px;
  top: 3px;
}
